<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <div class="col-3">
              <CHeaderNav>
                <CHeaderNavItem class="px-3">
                  <button
                    class="c-header-nav-btn"
                    @click="openSidebarEvent('orderEArchiveFilter')"
                  >
                    <CIcon size="lg" name="cil-filter" class="mr-2"/>
                  </button>
                </CHeaderNavItem>
              </CHeaderNav>
            </div>
            <div class="col-9 text-right">
              <CButton color="dark" square size="sm" :disabled="selectedInvoices.length===0"
               @click="openModalEvent('resendInvoice', 'confirm', selectedInvoices, 'Onay', selectedInvoices.map(si => si.id)+' Seçtiğiniz faturaları tekrar iletmek istiyor musunuz? ')">
                Tekrar İletilsin
              </CButton>&nbsp;
              <CButton color="primary" square size="sm" @click="getExcelByEArchiveInvoices">Excel'e Aktar</CButton>
            </div>
          </div>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            :items="eArchiveInvoices"
            :fields="fields"
            :items-per-page="10"
            :active-page="1"
            :loading="loading"
            hover
            pagination
            clickable-rows
            @row-clicked="rowClicked"
            @row-double-clicked="modalHandle('updateEttn')"
            v-on:refresh="filterEArchiveInvoices(filterParams)"
          >
            <template #select="{item,index}">
              <td>
                <CInputCheckbox
                  :checked="item._selected"
                  @update:checked="() => check(item,index)"
                  custom
                />
              </td>
            </template>
            <template #show_details="{item, index}">
              <td class="py-2">
                <CDropdown
                  toggler-text="İşlemler"
                  class="m-2"
                  color="info"
                  size="sm"
                >
                  <CDropdownItem @click="openModalEvent('update', 'orderETTNForm', selectedInvoices[0], 'ETTN No Giriş/Güncelleme Ekranı')">
                    ETTN Girişi
                  </CDropdownItem>
                  <CDropdownItem @click="getInvoicePdfLink" :disabled="selectedInvoice.ettn=== ''">
                    E-Arşiv Fatura Bas
                  </CDropdownItem>
                </CDropdown>
              </td>
            </template>

            <template #informedCancelledFlag="{item}">
              <td>
                <CIcon v-if="item.informedCancelledFlag" class="green"
                       name="cil-check-alt"/>
                <CIcon v-else name="cil-x" class="red"/>
              </td>
            </template>
            <template #informedFlag="{item}">
              <td>
                <CIcon v-if="item.informedFlag" class="green"
                       name="cil-check-alt"/>
                <CIcon v-else name="cil-x" class="red"/>
              </td>
            </template>
            
            <template #invoicePrintTime="{item}">
              <td> {{ item.invoicePrintTime && dateFormat(item.invoicePrintTime) }}</td>
            </template>
            <template #createTime="{item}">
              <td> {{ dateFormat(item.createTime) }}</td>
            </template>
            <template #informedTime="{item}">
              <td> {{ item.informedTime && dateFormat(item.informedTime) }}</td>
            </template>
            <template #invoiceStatus="{item}">
              <td> <CBadge :color="colors[invoiceType.findIndex(it => it == item.invoiceStatus)]"> {{ item.invoiceStatus }}</CBadge></td>
            </template>    
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
    <ConfirmModal
      v-if="form == 'confirm'" 
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :confirm="operationEvent"
      :desc="modalDesc"
      :data="modalProps"
      :actionType="actionType"
      :loading="loading"
      :size="confirmModalSize"
      ref="confirm"
      :isFrame="isFrame"
      :noFooter="noFooter"
    />
    <FormModal 
      v-else
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :operationEvent="operationEvent"
      :actionType="actionType"
      :module="module"
      :form="form"
      :data="modalProps"
    />
    <FilterSidebar 
      :show="openSidebar" 
      :openSidebarEvent="openSidebarEvent"
      :operationEvent="filterEArchiveInvoices"
      :module="module"
      :form="filterForm"
      :data="filterParams"
      :loading="loading"
    />
  </CRow>
</template>
<script>

import moment from 'moment';
import FormModal from '../components/formModal'
import ConfirmModal from '../components/confirmModal'
import FilterSidebar from '../components/filterSidebar'
import { prepareFilterParams } from "@/helpers/custom"

export default {
  name: 'EArchiveInvoices',
  components: {FilterSidebar, ConfirmModal, FormModal},
  data() {
    return {
      isFrame: false,
      confirmModalSize: 'sm',
      modalSize: 'lg',
      colors: [ 'warning',  'info', 'primary',  'danger', 'secondary', 'dark', 'success'],
      invoiceType: ['prepared', 'canceled', 'printed'],
      modalTitle: '',
      actionType: '',
      module: 'orders',
      form: 'orderUpdatePayment',
      modalProps: {},
      modalDesc: '',
      openModal: false,
      openSidebar: false,
      filterForm: 'orderEArchiveFilter',
      filterParams: {},
      lastItem: {},
      noFooter: false,
      additionalButtons: [],
      activePage: 1,
      itemsPerPage: 10,
      selectedInvoice: {},
      selectedInvoices: [],
      fields: [
        {
          key: 'select',
          label: '',
          _style: 'min-width:1%',
          sorter: false,
          filter: false
        },
        {
          key: 'show_details',
          label: 'İşlemler',
          _style: 'font-size:12px'
        },
        {key: 'id', label: 'Inv. Id', _style: 'font-size:12px'},
        {key: 'createTime', label: 'Oluşturma Tarihi', _style: 'font-size:12px'},
        {key: 'invoicePrintTime', label: 'Fatura Tarihi', _style: 'font-size:12px'},
        {key: 'ettn', label: 'ETTN', _style: 'font-size:12px'},
        {key: 'orderId', label: 'Order ID', _style: 'font-size:12px'},
        {key: 'invoiceStatus', label: 'Durumu', _style: 'font-size:12px'},
        {key: 'informedCancelledFlag', label: 'İptal Bildirimi', _style: 'font-size:12px'},
        {key: 'informedFlag', label: 'Logo İletimi', _style: 'font-size:12px'},
        {key: 'informedTime', label: 'İletilme Zamanı', _style: 'font-size:12px'}
      ],
      filters: [
        { type: 'string', dataIndex: 'id', field: 'id', comparison: 'eq'},
        { type: 'string', dataIndex: 'ettn', field: 'ettn'},
        { type: 'string', dataIndex: 'orderId', field: 'orderId', comparison: 'eq'},
        { type: 'boolean', dataIndex: 'informedCancelledFlag', field: 'informedCancelledFlag' }, 
        { type: 'boolean', dataIndex: 'informedFlag', field: 'informedFlag'},
        { type: 'date', dataIndex: 'informedTime', field: 'informedTime', comparison:'eq'},
        { type: 'date', dataIndex: 'invoicePrintTime', field: 'invoicePrintTime', comparison:'eq' },
        { type: 'date', dataIndex: 'createTimeStart', field: 'createTime', comparison:'gt'},
        { type: 'date', dataIndex: 'createTimeEnd', field: 'createTime', comparison:'lt'},
        { type: 'date', dataIndex: 'invoiceTimeStart', field: 'invoicePrintTime', comparison: 'gt' },
        { type: 'date', dataIndex: 'invoiceTimeEnd', field: 'invoicePrintTime', comparison:'lt'},
      ]
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    },
  },
  computed: {
    loading() {
      return this.$store.getters.orderLoading
    },
    eArchiveInvoices() {
      return this.$store.getters.orderEArchiceInvoices
    }
  },
  methods: {
    async openModalEvent(actionType, form, data, title, desc){
      this.confirmModalSize = 'sm'
      this.modalSize = 'lg'
      this.isFrame = false
      this.additionalButtons = []
      this.noFooter = false
      if(actionType == 'print'){
        this.confirmModalSize = 'xl'
        this.isFrame = true
      }
      this.openModal = !this.openModal
      this.actionType = actionType
      this.form = form
      this.modalProps = data
      this.modalTitle = title
      this.modalDesc = desc
    },

    openSidebarEvent(form){
      this.openSidebar = !this.openSidebar
      this.filterForm = form
    },

    dateFormat(date){
      return moment(date).format('DD.MM.YYYY HH:mm:ss')
    },

    async operationEvent(item, action){
      if(action == 'resendInvoice'){
        await this.$store.dispatch('order_resendInvoices',  { "invoiceIds": item.map(i => i.id).join(',')})
      }else if( this.form == 'orderETTNForm'){
        let params = {id: this.selectedInvoice.id, ettn: item.ettn, }
        await this.$store.dispatch('order_invoiceEttn', params)
      }
      if(this.$store.getters.orderStatus.success){
        this.filterEArchiveInvoices(this.filterParams)
        this.openModalEvent()
        this.selectedInvoices = []
      }
    },
    rowClicked(item, index, column, e) {
      if (column !== "select") {
        this.selectedInvoices = [];
        this.selectedInvoice = {};
        for(var i = 0; i <this.eArchiveInvoices.length; i++){
          this.$set(this.eArchiveInvoices[i], '_selected', false);
        }
        const val = Boolean(this.eArchiveInvoices[index]._selected);
        this.$set(this.eArchiveInvoices[index], '_selected', !val);
        this.eArchiveInvoices.map(u => {
          if (u._selected) {
            this.selectedInvoices.push(u);
          }
        });
        this.selectedInvoice = this.selectedInvoices[0];
      } else {
        this.check(item,index);
      }
    },
    check(item, index) {
      this.selectedInvoices = [];
      this.selectedInvoice = {};
      const val = Boolean(this.eArchiveInvoices[index]._selected)
      this.$set(this.eArchiveInvoices[index], '_selected', !val)
      this.eArchiveInvoices.map(u => {
        if (u._selected) {
          this.selectedInvoices.push(u);
        }
      });
    },
   
    async getExcelByEArchiveInvoices() {
      await this.$store.dispatch('order_eArchiveExcel',{responseType: 'blob'})
      if(this.$store.getters.orderStatus){
        const url = window.URL.createObjectURL(new Blob([this.$store.getters.orderStatus]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'ArşivFaturalar_' + moment(new Date).format('DD/MM/YYYY HH:mm:ss') + '.xls');
        document.body.appendChild(link);
        link.click();
      }
    },
   
    async getInvoicePdfLink() {
      await this.$store.dispatch('order_getInvoicePdf',{"id": this.selectedInvoices[0].id, "order": false });
      if(this.$store.getters.orderStatus.success){
        await this.openModalEvent('print', 'confirm', null, 'E-Arşiv Fatura: '+ this.selectedInvoices[0].id )
        this.$refs.confirm.$refs.desc.src= this.$store.getters.orderStatus.pdf
        this.noFooter = true
      }
    },
   
    async filterEArchiveInvoices(params) {
      this.filterParams = params
      let formData = prepareFilterParams(params, this.filters)

      await this.$store.dispatch('order_eArchiveInvoices', formData)

      if(this.openSidebar){
        await this.openSidebarEvent()
      }
    },
  },
  created() {
    this.filterEArchiveInvoices(this.filterParams)
  }
}
</script>
